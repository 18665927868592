/* eslint-disable max-len */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable no-console */
import Swiper from 'swiper/swiper-bundle.esm.js';
import 'swiper/swiper-bundle.min.css';

const initSwiper = () => {
  const swipers = [];

  const aboutCorporSwiper = new Swiper('.about-corporate-swiper', {
    slidesPerView: 1,
    spaceBetween: 40,
    // slideToClickedSlide: true,
    allowTouchMove: true,
    watchSlidesProgress: true,
    pagination: {
      el: '.about-corporate-swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      1080: {
        allowTouchMove: false,
        effect: 'fade',
      },
    },
    // allowTouchMove: false,
  });
  const quickSmallSwiper = new Swiper('.quick-small-swiper', {
    spaceBetween: 60,
    slidesPerView: 6,
    slideToClickedSlide: true,
    watchSlidesProgress: true,
    allowTouchMove: false,
    observer: true,
    observeParents: true,
  });
  const quickBigSwiper = new Swiper('.quick-big-swiper', {
    slidesPerView: 3,
    spaceBetween: 40,
    slideToClickedSlide: true,
    watchSlidesProgress: true,
    observer: true,
    observeParents: true,
    // allowTouchMove: false,
    grid: {
      // fill: 'col',
      rows: 2,
    },
    on: {
      slideChange: function () {
        console.log('this.slidesPerView', this.slidesPerView);
        quickSmallSwiper.slideTo(this.activeIndex * 2, 1000, true);
      },
    },
  });
  const smallNext = document.querySelector('.quick-small-swiper-button-next');
  const smallPrev = document.querySelector('.quick-small-swiper-button-prev');
  if (smallNext || smallPrev) {
    smallNext.addEventListener('click', () => {
      const currentBigI = quickBigSwiper.activeIndex;
      const currentSmallI = quickSmallSwiper.activeIndex;
      quickSmallSwiper.slideTo(currentSmallI + 6, 1000, true);
      quickBigSwiper.slideTo(currentBigI + 3, 1000, true);
    });
    smallPrev.addEventListener('click', () => {
      const currentBigI = quickBigSwiper.activeIndex;
      const currentSmallI = quickSmallSwiper.activeIndex;
      quickSmallSwiper.slideTo(currentSmallI - 6, 1000, true);
      quickBigSwiper.slideTo(currentBigI - 3, 1000, true);
    });
  }
  swipers.push(
    new Swiper('.quick-advantage-swiper', {
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: '.quick-advantage-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1360: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
    }),
  );
  swipers.push(
    new Swiper('.home-latest-swiper', {
      spaceBetween: 40,
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
        },
      },
      observer: true,
      observeParents: true,
    }),
  );
  swipers.push(
    new Swiper('.home-banner-swiper', {
      speed: 600,
      slidesPerView: 1,
      spaceBetween: 10,
      parallax: true,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 8000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      // navigation: {
      //   nextEl: '.home-banner-swiper-button-next',
      //   prevEl: '.home-banner-swiper-button-prev',
      // },
      pagination: {
        el: '.home-banner-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        1280: {
          speed: 1000,
        },
      },
    }),
    new Swiper('.agg-workshop-swiper', {
      loop: true,
      speed: 600,
      slidesPerView: 1.2,
      spaceBetween: 20,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.agg-workshop-swiper-button-next',
        prevEl: '.agg-workshop-swiper-button-prev',
      },
      breakpoints: {
        768: {
          speed: 600,
          slidesPerView: 2.5,
          spaceBetween: 20,
        },
        1280: {
          speed: 1000,
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1920: {
          speed: 1000,
          slidesPerView: 'auto',
          spaceBetween: 60,
        },
      },
    }),
  );
  document.querySelectorAll('.agg-pro-max-swiper').forEach((e, i) => {
    swipers.push(
      new Swiper(`.agg-pro-max-swiper-${i}`, {
        speed: 600,
        slidesPerView: 1.1,
        spaceBetween: 10,
        navigation: {
          prevEl: `.agg-pro-swiper-button-${i}-prev`,
          nextEl: `.agg-pro-swiper-button-${i}-next`,
        },
        breakpoints: {
          1280: {
            speed: 1000,
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
        thumbs: {
          swiper: {
            el: `.agg-pro-min-swiper-${i}`,
            speed: 1000,
            spaceBetween: 30,
            slidesPerView: 5,
            watchSlidesVisibility: true,
          },
          autoScrollOffset: 1,
        },
      }),
    );
  });
  swipers.push(
    new Swiper('.about-focus-swiper', {
      slidesPerView: 1,
      spaceBetween: 40,
      observeParents: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
        },
      },
      pagination: {
        el: '.about-focus-swiper-pagination',
        clickable: true,
      },
    }),
  );
  swipers.push(quickSmallSwiper);
  swipers.push(quickBigSwiper);
  // swipers.push(quickSmallSwiper);
  // swipers.push(aboutCorporSwiper);
  // const aggProMaxSwiper = new Swiper('.agg-pro-max-swiper', {
  //   speed: 1000,
  //   parallax: true,
  //   navigation: {
  //     prevEl: '.agg-pro-swiper-button-prev',
  //     nextEl: '.agg-pro-swiper-button-next',
  //   },
  //   thumbs: {
  //     swiper: {
  //       el: '.agg-pro-min-swiper',
  //       speed: 1000,
  //       spaceBetween: 30,
  //       slidesPerView: 5,
  //       watchSlidesVisibility: true,
  //     },
  //     autoScrollOffset: 1,
  //   },
  // });
  return { quickBigSwiper, aboutCorporSwiper };
};

export default initSwiper;
