/* eslint-disable prefer-const */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
// vue
import { createApp } from 'petite-vue';
// fancyapps
import '@fancyapps/ui/dist/fancybox.esm';
import '@fancyapps/ui/dist/fancybox.css';
// bootstrap icons
import 'bootstrap-icons/font/bootstrap-icons.css';
// countUp

import initCountUp from './countup';
// gsap
import initGSAP from './gsap';
// swiper
import initSwiper from './swiper';
// tailwindcss
import '../css/input.css';

import {
  getAllIndustry, getSomeApp, getSomeFun, getSomeProd,
} from './api';

createApp({
  // data
  loadingToggle: true,
  requesingToggle: false,
  sidebarToggle: false,
  contactToggle: false,
  searchToggle: false,
  videoToggle: false,
  videoSrc: '',
  allIndustrys: {},
  someApps: {},
  someFuns: {},
  someProds: {},
  industrys: {},
  apps: {},
  funs: {},
  prods: {},
  mobileMenuToggle: false,
  toPosi: Function,
  zoomIn: Function,
  zoomOut: Function,
  theSwiper: null,
  theSwiperSelectIndex: null,
  playV: true,
  showOne: 0,
  aggIndToggle: false,
  aggProIndex: 0,
  aggFAQIndex: 0,
  // removeActiveClassFn: Function,
  x: '50%',
  y: '50%',
  // methods
  toPosiFn() {
    this.toPosi();
  },
  ToSwiperIndexFn(i, num = 1) {
    this.theSwiper.slideTo(i / num);
    console.log(i, num);
  },
  handleDisabled(cur) {
    if (Array.isArray(cur) && cur.length === 0) {
      return false;
    }
    if (Object.keys(cur).length === 0) {
      return 'disabled';
    }
    return false;
  },
  toggleMore(compareItems, overHeight) {
    compareItems.forEach((compareItem) => {
      if (compareItem.clientHeight >= overHeight) {
        compareItem.classList.add('toggleText');
      }
    });
  },
  // alignHeight(alignHeight, changeItem) {
  //   // changeItem.style.height = `${alignHeight}px`;
  //   console.log('changeItem', changeItem);
  // },
  handleIndustry(cur) {
    if (cur === 'Any') {
      this.someApps = {};
      this.someFuns = {};
      this.someProds = {};
      return;
    }
    this.requesingToggle = true;
    getSomeApp({ action: 'get_application_data', industry: cur }).then((res) => {
      this.someApps = res.data;
      this.requesingToggle = false;
    });
  },
  handleApps(cur) {
    if (cur === 'Any') {
      this.someFuns = {};
      this.someProds = {};
      return;
    }
    this.requesingToggle = true;
    getSomeFun({ action: 'get_function_data', application: cur }).then((res) => {
      this.someFuns = res.data;
      this.requesingToggle = false;
    });
  },
  handleFuns(cur) {
    if (cur === 'Any') {
      this.someProds = {};
      return;
    }
    this.requesingToggle = true;
    getSomeProd({ action: 'get_product_grounp_data', function: cur }).then((res) => {
      this.someProds = res.data;
      this.requesingToggle = false;
    });
  },
  controlVideo(e) {
    if (this.playV) {
      e.currentTarget.querySelector('video').play();
    } else {
      e.currentTarget.querySelector('video').pause();
    }
    this.playV = !this.playV;
    this.selfActiveToggleFn(e);
  },
  docToggleFn(val) {
    if (val) {
      document.querySelector('body').classList.add('doc--active');
    } else {
      document.querySelector('body').classList.remove('doc--active');
    }
  },
  mobileMenuToggleFn(e, toggle) {
    this.popAnimationFn(e, toggle, '#mobile-menu', 'mobileMenu', 'left');
  },
  parentActiveToggleFn(e) {
    e.currentTarget.parentElement.classList.toggle('active');
  },
  grandParentActiveToggleFn(e) {
    e.currentTarget.parentElement.parentElement.classList.toggle('active');
  },
  oneFlag: 0,
  toggleOneFn(index) {
    this.oneFlag = index;
    this.ToSwiperIndexFn(index);
  },
  selfActiveToggleFn(e) {
    e.currentTarget.classList.toggle('active');
  },
  childActiveToggleFn(e) {
    e.currentTarget.querySelector('.drop_down_icon').classList.toggle('active');
  },
  removeParentActiveFn(e) {
    e.currentTarget.parentElement.classList.remove('active');
  },
  removeActiveFn(e) {
    e.currentTarget.classList.remove('active');
  },
  removeTheChildActiveFn(e) {
    e.currentTarget.children[0].classList.remove('active');
  },
  contactToggleFn(e, toggle) {
    this.popAnimationFn(e, toggle, '#pop-form', 'contact');
  },
  searchToggleFn(e, toggle) {
    this.popAnimationFn(e, toggle, '#pop-search', 'search');
  },
  videoToggleFn(e, toggle) {
    this.popAnimationFn(e, toggle, '#pop-video', 'video');
  },
  popAnimationFn(e, toggle, selector, state, posi) {
    if (e) {
      this.x = e.clientX;
      this.y = e.clientY;
    }
    if (toggle) {
      if (selector === '#pop-video') {
        this.videoSrc = e.currentTarget.dataset.src;
      }
      this.docToggleFn(toggle);
      this[`${state}Toggle`] = toggle;
      this.zoomIn(selector, this.x, this.y, posi);
    } else {
      if (selector === '#pop-video') {
        this.videoSrc = '';
      }
      this.zoomOut(selector, this.x, this.y, posi);
      const timeId = setTimeout(() => {
        this.docToggleFn(toggle);
        this[`${state}Toggle`] = toggle;
        this.x = '50%';
        this.y = '50%';
        clearTimeout(timeId);
      }, 480);
    }
  },
  // mounted
  mounted() {
    // // 节流函数
    // function throttle(func, limit) {
    //   let inThrottle;
    //   return () => {
    //     if (!inThrottle) {
    //       func.apply(this, arguments);
    //       inThrottle = true;
    //       setTimeout(() => inThrottle = false, limit);
    //     }
    //   };
    // }
    // // 获取处理窗口大小变化的函数
    // function handleResize() {
    //   // 在这里编写处理窗口大小变化的代码
    //   if (document.querySelector('.aboutContent')) {
    //     const originItemH = document.querySelector('.aboutContent').offsetHeight;
    //     let changeItem = document.querySelector('.aboutImg');
    //     // this.toggleMore(descBoxs, 144);
    //     console.log('originItem', originItemH, 'changeItem', changeItem);
    //     // this.alignHeight(originItemH, changeItem);
    //   }
    // }
    // const throttledResizeHandler = throttle(handleResize, 300);
    // window.addEventListener('resize', throttledResizeHandler);

    if (document.querySelector('.ifShowButton .button')) {
      const descBoxs = document.querySelectorAll('.ifShowButton .desc');
      this.toggleMore(descBoxs, 144);
    }

    this.loadingToggle = false;
    getAllIndustry({ action: 'get_industry_data' }).then((res) => {
      this.allIndustrys = res.data;
    });
    // initSwiper
    const swiper = initSwiper();
    this.theSwiper = swiper.aboutCorporSwiper;
    this.theSwiperSelectIndex = swiper.theSwiperIndex;
    // this.aggProMaxSwiperFn = swiper.aggProMaxSwiper;
    // this.aggProMaxSwiperFn.on('slideChange', (e) => {
    //   this.aggProIndex = e.realIndex;
    // });
    // initCountUp
    initCountUp();
    // initGSAP
    const gsapObj = initGSAP();
    this.toPosi = gsapObj.scrollTo;
    this.zoomIn = gsapObj.zoomIn;
    this.zoomOut = gsapObj.zoomOut;
    // scroll
    let ticking = false;
    let { scrollY } = window;
    window.addEventListener('scroll', () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          // do something...
          if (scrollY > 50) {
            this.sidebarToggle = true;
          } else {
            this.sidebarToggle = false;
          }
          scrollY = window.scrollY;
          ticking = false;
        });
        ticking = true;
      }
    });
  },
}).mount('#app');
