/* eslint-disable import/prefer-default-export */
import request from './http';
// 获取所有选项 start
export function getAllIndustry(query) {
  return request({
    method: 'get',
    params: query,
  });
}
// 根据上一个选项值获取 接下来的选项框出现的值
export function getSomeApp(query) {
  return request({
    method: 'post',
    params: query,
  });
}
export function getSomeFun(query) {
  return request({
    method: 'post',
    params: query,
  });
}
export function getSomeProd(query) {
  return request({
    method: 'post',
    params: query,
  });
}
